












import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<PointOfService>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<PointOfService> {
        return (
          this.value || PointOfServiceUtils.pointOfService.generateDefault()
        );
      },
      set(val: Partial<PointOfService>) {
        this.$emit('input', val);
      },
    },
  },
});
