









































































































import Vue from 'vue';
import PointOfServiceForm from '@/components/pointofservice/PointOfServiceForm.vue';
import PointOfServiceDescriptionForm from '@/components/pointofservice/PointOfServiceDescriptionForm.vue';
import PointOfServiceServiceForm from '@/components/pointofservice/PointOfServiceServiceForm.vue';
import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';

export default Vue.extend({
  components: {
    PointOfServiceForm,
    PointOfServiceDescriptionForm,
    PointOfServiceServiceForm,
  },

  computed: {
    table() {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('pointofservice.name'),
            value: 'name',
          },
          {
            text: this.$i18n.tc('pointofservice.department'),
            value: 'department',
          },
          {
            text: this.$i18n.tc('pointofservice.address'),
            value: 'address',
          },
          {
            text: this.$i18n.tc('address.geocode_confidence'),
            value: 'data_quality.geocoder_confidence',
          },
          {
            text: this.$i18n.tc('provider.label'),
            value: 'service_provider.name_short',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },
  },

  data: (): {
    item?: Partial<PointOfService>;
    items?: PointOfService[];
    disableGeocoder?: boolean;
    state: {
      visible: boolean;
      remove?: Partial<PointOfService>;
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
      tab?: string;
    };
  } => ({
    item: undefined,
    items: undefined,
    disableGeocoder: false,
    state: {
      visible: false,
      remove: undefined,
      valid: false,
      submit: false,
      delete: false,
      errors: undefined,
      tab: undefined,
    },
  }),

  async mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.items = await PointOfServiceUtils.api.list({
          service_provider: parseInt(this.$route.params?.id),
        });
      } catch (error) {
        console.error(error);
      } finally {
        //
      }
    },

    async handleEdit(item: PointOfService) {
      try {
        this.disableGeocoder = true;
        const result = await PointOfServiceUtils.api.get(item.id);
        this.item = PointOfServiceUtils.pointOfService.generateDefault(result);

        setTimeout(() => {
          // delay enabling geocoder for a bit so it will not trigger as soon as the item is set
          this.disableGeocoder = false;
        }, 50);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 404) {
          // TODO: not found
        }
      }
    },

    async handleCreate() {
      try {
        this.item = PointOfServiceUtils.pointOfService.generateDefault({
          service_provider: {
            id: parseInt(this.$route.params?.id),
          },
        });
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 404) {
          // TODO: not found
        }
      }
    },

    async handleCancel() {
      this.item = undefined;
    },

    async handleDelete(item: PointOfService) {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await PointOfServiceUtils.api.remove(item.id);
        await this.load();
        this.$toast.push({
          text: this.$t('success.delete', [this.$tc('pointofservice.label')]),
          type: 'success',
        });
        this.state.remove = undefined;
      } catch (error: any) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('pointofservice.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },

    async handleSubmit() {
      if (this.item) {
        const type = this.item.id ? 'edit' : 'create';
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();

          if (type === 'edit') {
            this.item = PointOfServiceUtils.pointOfService.generateDefault(
              await PointOfServiceUtils.api.update(this.item)
            );
          } else {
            this.item = PointOfServiceUtils.pointOfService.generateDefault(
              await PointOfServiceUtils.api.create(this.item)
            );
          }

          await PointOfServiceUtils.api.generateSearchDataset(this.item?.id);
          await this.load();

          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t(type === 'edit' ? 'success.edit' : 'success.create', [
              this.$tc('pointofservice.label'),
            ]),
            type: 'success',
          });

          this.item = undefined; // close modal
        } catch (error: any) {
          this.$toast.push({
            text: this.$t(type === 'edit' ? 'error.edit' : 'error.create', [
              this.$tc('pointofservice.label'),
            ]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },

  watch: {
    item: {
      handler(value) {
        if (!value) {
          this.state.tab = undefined;
        }
      },
    },
  },
});
