















import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import Vue from 'vue';
import ServicePicker from '../service/ServicePicker.vue';

export default Vue.extend({
  components: {
    ServicePicker,
  },

  props: {
    value: {
      type: Object as () => Partial<PointOfService>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<PointOfService> {
        return (
          this.value || PointOfServiceUtils.pointOfService.generateDefault()
        );
      },
      set(val: Partial<PointOfService>) {
        this.$emit('input', val);
      },
    },

    service_override: {
      get(): any {
        return {
          value: this.internal.override_provider_services,
          label: this.$tc('pointofservice.serviceOverride'),
          items:
            this.providers?.find(
              (provider) => provider.id === this.internal.service_provider?.id
            )?.services || [],
        };
      },
      set(item: any) {
        this.internal.override_provider_services = item.value;
      },
    },
  },

  data(): {
    providers?: Provider[];
  } {
    return {
      providers: undefined,
    };
  },

  async mounted() {
    this.providers = await ProviderUtils.api.list();
  },
});
